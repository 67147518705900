import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Бондын үнэлгээний тооцоолуур хэрхэн ашиглах вэ?",
  "description": null,
  "author": "OTC help",
  "category": "calculator",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Бондын үнэлгээний тооцоолуур хэрхэн ашиглах вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Оролцогч үндсэн цэсний `}<strong parentName="li">{`[Calculator]`}</strong>{` хэсэг дэх тооцоолуурыг ашиглан бондын үнэ болон өгөөжийг тооцох боломжтой`}</li>
      <li parentName="ol">{`Бондын үнийг тооцохдоо `}<strong parentName="li">{`[Bond Pricing]`}</strong>{` цэс дээр дарж сонгосон бондын өгөгдлүүдийг дараах байдлаар оруулна:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Bond Face Value/Par Value]`}</strong>{` хэсэгт бондын нэрлэсэн үнэ,`}</li>
          <li parentName="ul"><strong parentName="li">{`[Annual Coupon Rate]`}</strong>{` хэсэгт жилийн хүү,`}</li>
          <li parentName="ul"><strong parentName="li">{`[Discount Bond]`}</strong>{` хэсэгт тухайн бонд хөнгөлөлттэй бонд эсэхийг тэмдэглэнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Maturity in Months]`}</strong>{` хэсэгт хугацаа, сараар`}</li>
          <li parentName="ul"><strong parentName="li">{`[Issue Date]`}</strong>{` хэсэгт бондын арилжаа эхэлсэн огноо`}</li>
          <li parentName="ul"><strong parentName="li">{`[Price Calculation Date]`}</strong>{` хэсэгт үнэ тооцож буй өдрийн огноо`}</li>
          <li parentName="ul"><strong parentName="li">{`[Coupon Payment Frequency]`}</strong>{` хэсэгт хүү төлөх хугацаа/давтамжийг`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`[Monthly]`}</strong>{` сар бүр,`}</li>
              <li parentName="ul"><strong parentName="li">{`[Quarterly]`}</strong>{` улирал бүр,`}</li>
              <li parentName="ul"><strong parentName="li">{`[Twice a year]`}</strong>{` хагас жил тутамд,`}</li>
              <li parentName="ul"><strong parentName="li">{`[Annual]`}</strong>{` жил бүр,`}</li>
              <li parentName="ul"><strong parentName="li">{`[At Maturity]`}</strong>{` хугацааны эцэст гэсэн сонголтуудаас сонгон оруулна.`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ol>
    <p>{`Дээрх өгөгдлүүдийг оруулан `}<strong parentName="p">{`[Calculate]`}</strong>{` товчийг дарснаар үнэ тооцоолж буй өдрийн байдлаарх бондын зах зээлийн үнэ `}<strong parentName="p">{`[Dirty Price (Market Price)]`}</strong>{`, цэвэр үнэ буюу нэрлэсэн үнэ болон хуримтлагдсан хүүг таван орны нарийвчлалтайгаар тооцон харуулна.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Тооцоолуураар гарсан үр дүнг `}<strong parentName="li">{`[Export CSV]`}</strong>{` товчийг дарж .csv өргөтгөлтэйгөөр татаж авах боломжтой.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      